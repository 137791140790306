import {
  Directive,
  Optional,
  Inject,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  Host,
  OnInit
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlErrorContainerDirective } from './error-container.directive';
import { merge, EMPTY, Observable } from 'rxjs';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { FormSubmitDirective } from './form-submit.directive';
import { FORM_ERRORS } from './errors';
import { takeUntil, filter } from 'rxjs/operators';
import { Destroyer } from '@apps/tools';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective extends Destroyer implements OnInit {
  container: ViewContainerRef;
  submit$: Observable<Event>;
  @Input() errorRef:
    | ComponentRef<ErrorMessageComponent>
    | ErrorMessageComponent;
  @Input() customErrors = {};
  @Input() disableErrors = false;

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Inject(FORM_ERRORS) private errors,
    @Optional() @Host() private form: FormSubmitDirective,
    private controlDir: NgControl
  ) {
    super();
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;

    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    merge(this.submit$, this.control.valueChanges)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !this.disableErrors)
      )
      .subscribe(() => {
        const controlErrors = this.control.errors;
        if (controlErrors) {
          const firstKey = Object.keys(controlErrors)[0];

          let getError = this.errors[firstKey];
          if (this.customErrors[firstKey]) {
            getError = this.customErrors[firstKey];
          }

          let text = '';
          if (getError) {
            text = getError(controlErrors[firstKey]);
          }

          this.setError(text);
        } else if (this.errorRef) {
          this.setError(null);
        }
      });
  }

  get control() {
    return this.controlDir.control;
  }

  setError(text: string) {
    if (this.errorRef instanceof ErrorMessageComponent) {
      this.errorRef.text = text;
    } else {
      if (!this.errorRef) {
        const factory = this.resolver.resolveComponentFactory(
          ErrorMessageComponent
        );
        this.errorRef = this.container.createComponent(factory);
        this.errorRef.instance.text = text;
      }
    }
  }
}
