import { HttpClient } from '@angular/common/http';
import { API } from '@apps/api-interfaces';
import { StorageService } from '@apps/ui';
import { of } from 'rxjs';
import { tap, mapTo, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../../../libs/ui/src/lib/services/storage.service";
export class SessionService {
    constructor(http, storage) {
        this.http = http;
        this.storage = storage;
        this.JWT_TOKEN = 'JWT_TOKEN';
        this.REFRESH_TOKEN = 'REFRESH_TOKEN';
    }
    get isRoleAdminSuper() {
        return !!this.getLoggedUserRoles().super;
    }
    get isRoleAdmin() {
        return !!this.getLoggedUserRoles().admin;
    }
    get isRoleAf() {
        return !!this.getLoggedUserRoles().af;
    }
    login(user) {
        const url = `${API.AUTH}/login`;
        return this.http.post(url, user).pipe(tap(tokens => this.doLoginUser(user.email, tokens)), mapTo(true), catchError(error => {
            return of(false);
        }));
    }
    logout() {
        this.doLogoutUser();
        return of(true);
        // const url = `${API.AUTH}/logout`;
        // return this.http
        //   .post<any>(url, {
        //     refresh_token: this.getRefreshToken()
        //   })
        //   .pipe(
        //     tap(() => this.doLogoutUser()),
        //     mapTo(true),
        //     catchError(error => {
        //       alert(error.error);
        //       return of(false);
        //     })
        //   );
    }
    getJwtToken() {
        return this.storage.getItem(this.JWT_TOKEN);
    }
    isLoggedIn() {
        return !!this.getJwtToken();
    }
    refreshToken() {
        const url = `${API.AUTH}/refresh`;
        return this.http
            .post(url, {
            refreshToken: this.getRefreshToken()
        })
            .pipe(tap((tokens) => {
            this.storeJwtToken(tokens.access_token);
        }));
    }
    getLoggedUserRoles() {
        const data = this.parseJwt(this.getJwtToken());
        return (data && data.roles) || {};
    }
    getLoggedUserName() {
        const data = this.parseJwt(this.getJwtToken());
        return data && data.email;
    }
    getLoggedUserCID() {
        const data = this.parseJwt(this.getJwtToken());
        return data && data.username;
    }
    getLoggedUserId() {
        const data = this.parseJwt(this.getJwtToken());
        return data && data.sub;
    }
    doLogoutUser() {
        this.loggedUser = null;
        this.removeTokens();
    }
    doLoginUser(email, tokens) {
        this.loggedUser = email;
        this.storeTokens(tokens);
    }
    getRefreshToken() {
        return this.storage.getItem(this.REFRESH_TOKEN);
    }
    storeJwtToken(jwt) {
        this.storage.setItem(this.JWT_TOKEN, jwt);
    }
    storeTokens(tokens) {
        this.storage.setItem(this.JWT_TOKEN, tokens.access_token);
        this.storage.setItem(this.REFRESH_TOKEN, tokens.refresh_token || '');
    }
    removeTokens() {
        this.storage.removeItem(this.JWT_TOKEN);
        this.storage.removeItem(this.REFRESH_TOKEN);
    }
    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StorageService)); }, token: SessionService, providedIn: "root" });
