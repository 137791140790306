<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="form">
                  <h1>Logowanie</h1>
                  <p class="text-muted">Panel programu partnerskiego</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="text" formControlName="email" class="form-control" placeholder="Email" autocomplete="username" [disableErrors]="true">
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" formControlName="password" class="form-control" placeholder="Hasło" autocomplete="current-password" [disableErrors]="true">
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button type="button" (click)="login()" [disabled]="!form.valid" class="btn btn-primary px-4">Zaloguj</button>
                    </div>
                    <div class="col-6 text-right">
                      <!-- <button type="button" class="btn btn-link px-0">Nie pamiętam hasła</button> -->
                    </div>
                  </div>
                  <sc-error *ngIf="error" text="Błąd logowania"></sc-error>
                </form>
              </div>
            </div>
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <h2>Rejestracja</h2>
                  <p>Dołącz do programu partnerskiego i zacznij zarabiać na polecaniu wirtualnego asystenta biznesowego ZatrzymajLicznik.pl</p>
                  <button type="button" routerLink="/register" class="btn btn-primary active mt-3">Zarejestruj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
