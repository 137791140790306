import { INavData } from '@coreui/angular';
import { environment } from '../environments/environment';

let _navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer -af- -admin- -super-'
  },
  {
    name: 'Zamówienia',
    url: '/orders',
    icon: 'fa fa-shopping-bag -admin- -super-'
    // badge: {
    //   variant: 'info',
    //   text: 'NOWE'
    // }
  },
  {
    name: 'Szybkie zgłoszenia',
    url: '/fast-contact',
    icon: 'fa fa-fast-forward -admin- -super-'
    // badge: {
    //   variant: 'info',
    //   text: 'NOWE'
    // }
  },
  {
    name: 'Konwersje',
    url: '/provisions',
    icon: 'fa fa-bar-chart -af-'
  },
  {
    name: 'Kody rabatowe',
    url: '/discounts',
    icon: 'fa fa-barcode -af-'
  },
  {
    name: 'Ref-linki',
    url: '/refliks',
    icon: 'fa fa-link -af-'
  },
  {
    name: 'Widgety na stronę',
    url: '/widgets',
    icon: 'fa fa-puzzle-piece -af-'
  },
  {
    name: 'Materiały reklamowe',
    url: '/commercials',
    icon: 'fa fa-file-image-o -af-'
  },
  {
    name: 'FAQ',
    url: '/faq',
    icon: 'fa fa-question-circle -af-'
  },
  {
    title: true,
    name: 'Porównywarka',
    icon: '-admin- -super-'
  },
  {
    name: 'Cenniki',
    url: '/prices',
    icon: 'fa fa-dollar -admin- -super-',
    children: [
      {
        name: 'Prąd',
        url: '/prices/prad',
        icon: 'fa fa-dollar -admin- -super-'
      },
      {
        name: 'Gaz',
        url: '/prices/gaz',
        icon: 'fa fa-dollar -admin- -super-'
      },
      {
        name: 'Fotowoltaika',
        url: '/fotowoltaika',
        icon: 'fa fa-dollar -admin- -super-'
      }
    ]
  },
  {
    name: 'Dostawcy',
    url: '/providers',
    icon: 'fa fa-bank -admin- -super-'
  },
  {
    name: 'Filtry',
    url: '/filters',
    icon: 'fa fa-filter -admin- -super-'
  },
  {
    title: true,
    name: 'Treści',
    icon: '-admin- -super-'
  },
  {
    name: 'Komunikaty',
    url: '/alerts',
    icon: 'fa fa-comment-o -admin- -super-'
  },
  {
    name: 'Bannery',
    url: '/banners',
    icon: 'fa fa-window-maximize -admin- -super-'
  },
  {
    name: 'Artykuły',
    url: '/articles',
    icon: 'fa fa-file-word-o -admin- -super-',
    children: [
      {
        name: 'Prąd',
        url: '/articles/prad',
        icon: 'fa fa-file-text-o'
      },
      {
        name: 'Gaz',
        url: '/articles/gaz',
        icon: 'fa fa-file-text-o'
      },
      {
        name: 'Fotowoltaika',
        url: '/articles/fotowoltaika',
        icon: 'fa fa-file-text-o'
      },
      {
        name: 'Kategorie',
        url: '/categories',
        icon: 'fa fa-inbox'
      }
    ]
  },
  {
    name: 'FAQ Partnerów',
    url: '/af-faq',
    icon: 'fa fa-question-circle -admin- -super-'
  },
  {
    title: true,
    name: 'Administracja'
  },
  {
    name: 'Użytkownicy',
    url: '/users',
    icon: 'fa fa-user-circle -admin- -super-'
  },
  {
    name: 'Ustawienia konta',
    url: '/user-settings',
    icon: 'fa fa-user-circle -af- -admin- -super-'
  },
  {
    name: 'Operacje',
    url: '/operations',
    icon: 'fa fa-user-circle -super-'
  }
];

if (environment.production as any !== true) {
  _navItems = [
    ..._navItems,
    {
      title: true,
      name: '==== Demo pages ====='
    },
    {
      title: true,
      name: 'Theme'
    },
    {
      name: 'Colors',
      url: '/theme/colors',
      icon: 'icon-drop -admin- -super-'
    },
    {
      name: 'Typography',
      url: '/theme/typography',
      icon: 'icon-pencil -admin- -super-'
    },
    {
      title: true,
      name: 'Components',
      icon: ' -admin- -super-'
    },
    {
      name: 'Base',
      url: '/base',
      icon: 'icon-puzzle -admin- -super-',
      children: [
        {
          name: 'Cards',
          url: '/base/cards',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Carousels',
          url: '/base/carousels',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Collapses',
          url: '/base/collapses',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Forms',
          url: '/base/forms',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Navbars',
          url: '/base/navbars',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Pagination',
          url: '/base/paginations',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Popovers',
          url: '/base/popovers',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Progress',
          url: '/base/progress',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Switches',
          url: '/base/switches',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Tables',
          url: '/base/tables',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Tabs',
          url: '/base/tabs',
          icon: 'icon-puzzle -admin- -super-'
        },
        {
          name: 'Tooltips',
          url: '/base/tooltips',
          icon: 'icon-puzzle -admin- -super-'
        }
      ]
    },
    {
      name: 'Buttons',
      url: '/buttons',
      icon: 'icon-cursor -admin- -super-',
      children: [
        {
          name: 'Buttons',
          url: '/buttons/buttons',
          icon: 'icon-cursor -admin- -super-'
        },
        {
          name: 'Dropdowns',
          url: '/buttons/dropdowns',
          icon: 'icon-cursor -admin- -super-'
        },
        {
          name: 'Brand Buttons',
          url: '/buttons/brand-buttons',
          icon: 'icon-cursor -admin- -super-'
        }
      ]
    },
    {
      name: 'Charts',
      url: '/charts',
      icon: 'icon-pie-chart -admin- -super-'
    },
    {
      name: 'Icons',
      url: '/icons',
      icon: 'icon-star -admin- -super-',
      children: [
        {
          name: 'CoreUI Icons',
          url: '/icons/coreui-icons',
          icon: 'icon-star -admin- -super-',
          badge: {
            variant: 'success',
            text: 'NEW'
          }
        },
        {
          name: 'Flags',
          url: '/icons/flags',
          icon: 'icon-star -admin- -super-'
        },
        {
          name: 'Font Awesome',
          url: '/icons/font-awesome',
          icon: 'icon-star -admin- -super-',
          badge: {
            variant: 'secondary',
            text: '4.7'
          }
        },
        {
          name: 'Simple Line Icons',
          url: '/icons/simple-line-icons',
          icon: 'icon-star -admin- -super-'
        }
      ]
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: 'icon-bell -admin- -super-',
      children: [
        {
          name: 'Alerts',
          url: '/notifications/alerts',
          icon: 'icon-bell -admin- -super-'
        },
        {
          name: 'Badges',
          url: '/notifications/badges',
          icon: 'icon-bell -admin- -super-'
        },
        {
          name: 'Modals',
          url: '/notifications/modals',
          icon: 'icon-bell -admin- -super-'
        }
      ]
    },
    {
      name: 'Widgets',
      url: '/widgets',
      icon: 'icon-calculator -admin- -super-',
      badge: {
        variant: 'info',
        text: 'NEW'
      }
    },
    {
      divider: true
    },
    {
      title: true,
      name: 'Extras',
    },
    {
      name: 'Pages',
      url: '/pages',
      icon: 'icon-star -admin- -super-',
      children: [
        {
          name: 'Login',
          url: '/login',
          icon: 'icon-star -admin- -super-'
        },
        {
          name: 'Register',
          url: '/register',
          icon: 'icon-star -admin- -super-'
        },
        {
          name: 'Error 404',
          url: '/404',
          icon: 'icon-star -admin- -super-'
        },
        {
          name: 'Error 500',
          url: '/500',
          icon: 'icon-star -admin- -super-'
        }
      ]
    },
    {
      name: 'Disabled',
      url: '/dashboard',
      icon: 'icon-ban',
      badge: {
        variant: 'secondary',
        text: 'NEW'
      },
      attributes: { disabled: true }
    }
  ];
}

export const navItems: INavData[] = _navItems;
