import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorContainerDirective } from './error-container.directive';
import { ControlErrorsDirective } from './error.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorMessageComponent } from './error-message/error-message.component';

@NgModule({
  declarations: [
    ControlErrorsDirective,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    ErrorMessageComponent
  ],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [
    ControlErrorsDirective,
    ControlErrorContainerDirective,
    ReactiveFormsModule,
    FormSubmitDirective,
    ErrorMessageComponent
  ],
  entryComponents: [ErrorMessageComponent]
})
export class FormModule {}
