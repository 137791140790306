import { Component } from '@angular/core';
import { navItems } from '../../_nav';
import { SessionService } from '@cms/shared/services';
import { Destroyer } from '@apps/tools';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { fadeAnimation } from '@apps/ui';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.scss'],
  animations: [fadeAnimation]
})
export class DefaultLayoutComponent extends Destroyer {
  public sidebarMinimized = false;
  public navItems = navItems;
  public menu: boolean;
  imageUrl = environment.frontUrl;
  private activeCloseMenu: boolean;


  constructor(public session: SessionService, private router: Router) {
    super();
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  toggleMenu(e: Event) {
    e.preventDefault();
    this.activeCloseMenu = false;
    this.menu = !this.menu;
    setTimeout(() => this.activeCloseMenu = true, 800);
  }

  closeMenu(e) {
    if (this.activeCloseMenu) {
      this.menu = false;
    }
  }

  logout() {
    this.session
      .logout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(success => {
        if (success) {
          this.router.navigate(['/login']);
        }
      });
  }
  
}
