import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from '../services';

@Injectable()
export class OnlyAfGuard implements CanActivate {
  constructor(private router: Router, private session: SessionService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (!!this.session.isLoggedIn()) {
      const roles = this.session.getLoggedUserRoles();
      return roles && roles.af;
    }

    this.router.navigate(['/']);
    return false;
  }
}
