import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from '../services';

@Injectable()
export class OnlyLoggedGuard implements CanActivate {
  constructor(private router: Router, private session: SessionService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (!!this.session.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
