import { InjectionToken } from '@angular/core';
import * as i0 from "@angular/core";
export const STORAGE_PREFIX = new InjectionToken('STORAGE_PREFIX', {
    providedIn: 'root',
    factory: () => 'app'
});
export class StorageService {
    constructor(prefix) {
        this.prefix = prefix;
    }
    getItem(name, session = false) {
        return this.convertFromJSON((session ? sessionStorage : localStorage).getItem(`${this.prefix}_${name}`));
    }
    setItem(name, value, session = false) {
        (session ? sessionStorage : localStorage).setItem(`${this.prefix}_${name}`, this.convertToString(value));
    }
    removeItem(name, session = false) {
        (session ? sessionStorage : localStorage).removeItem(`${this.prefix}_${name}`);
    }
    convertToString(data) {
        switch (typeof data) {
            case 'object':
                return JSON.stringify(data);
            case 'string':
                return data;
            default:
                return data.toString();
        }
    }
    convertFromJSON(data) {
        if (!data || !data[0] || !['{', '['].includes(data[0])) {
            return data;
        }
        return JSON.parse(data);
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.ɵɵinject(STORAGE_PREFIX)); }, token: StorageService, providedIn: "root" });
