import * as moment from 'moment';

export const slugify = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ąàáäâęèéëêłìíïîòóöôùúüûñçćśźżń·/_,:;';
  const to = 'aaaaaeeeeeliiiioooouuuunccszzn------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

export const sleep = (ms: number): Promise<any> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const extraLog = (data: any) => {
  console.log('****************************************');
  console.log(data);
  console.log('****************************************');
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function onlyUniqueObject(value, index, self) {
  return self.findIndex(e => JSON.stringify(e) === JSON.stringify(value)) === index;
}

export function onlyUniqueName(value, index, self) {
  return self.findIndex(e => e.name === value.name) === index;
}

export const sortByLocaleName = (a: string, b: string) => {
  return a.localeCompare(b, 'pl', { sensitivity: 'base' });
};

export const sortByDateField = (key: string, order: 'desc' | 'asc' = 'asc') => {
  return (a: any, b: any) => {
    const i = order === 'asc' ? 1 : -1;
    return moment(a[key]).isBefore(b[key]) ? -1 * i : 1 * i;
  };
};

export function onlyExistValues(e) {
  return e !== null && e !== undefined && e !== NaN;
}

export function sortByOrder(a, b) {
  return a.order > b.order ? 1 : -1;
}

export function sortByNumber(a, b) {
  return a > b ? 1 : -1;
}

export function stripTags(html) {
  if (!html || typeof html !== 'string') {
    return '';
  }
  return html.replace(/(<([^>]+)>)/gi, '');
}

export function Base64EncodeUrl(str){
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
}

export function Base64DecodeUrl(str){
  str = (str + '===').slice(0, str.length + (str.length % 4));
  return str.replace(/-/g, '+').replace(/_/g, '/');
}
