import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import {
  OnlyLogoutGuard,
  OnlyLoggedGuard,
  OnlyAdminOrSuperGuard,
  OnlyAfGuard
} from './_shared/guards';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [OnlyLogoutGuard],
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [OnlyLogoutGuard],
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [OnlyLoggedGuard],
    data: {
      title: 'Start'
    },
    children: [
      {
        path: 'users',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/page-users/page-users.module').then(
            m => m.PageUsersModule
          )
      },
      {
        path: 'prices',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/page-prices/page-prices.module').then(
            m => m.PagePricesModule
          )
      },
      {
        path: 'fotowoltaika',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/page-solars/page-solars.module').then(
            m => m.PageSolarsModule
          )
      },
      {
        path: 'provisions',
        loadChildren: () =>
          import('./views/page-provisions/page-provisions.module').then(
            m => m.PageProvisionsModule
          )
      },
      {
        path: 'orders',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/page-orders/page-orders.module').then(
            m => m.PageOrdersModule
          )
      },
      {
        path: 'fast-contact',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/page-fast-contact/page-fast-contact.module').then(
            m => m.PageFastContactModule
          )
      },
      {
        path: 'filters',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/page-filters/page-filters.module').then(
            m => m.PageFiltersModule
          )
      },
      {
        path: 'providers',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/page-providers/page-providers.module').then(
            m => m.PageProvidersModule
          )
      },
      {
        path: 'user-settings',
        loadChildren: () =>
          import('./views/page-user-settings/page-user-settings.module').then(
            m => m.PageUserSettingsModule
          )
      },
      {
        path: 'widgets',
        canActivate: [OnlyAfGuard],
        loadChildren: () =>
          import('./views/page-widgets/page-widgets.module').then(
            m => m.PageWidgetsModule
          )
      },
      {
        path: 'refliks',
        canActivate: [OnlyAfGuard],
        loadChildren: () =>
          import('./views/page-reflinks/page-reflinks.module').then(
            m => m.PageReflinksModule
          )
      },
      {
        path: 'discounts',
        canActivate: [OnlyAfGuard],
        loadChildren: () =>
          import('./views/page-discounts/page-discounts.module').then(
            m => m.PageDiscountsModule
          )
      },
      {
        path: 'commercials',
        canActivate: [OnlyAfGuard],
        loadChildren: () =>
          import('./views/page-commercials/page-commercials.module').then(
            m => m.PageCommercialsModule
          )
      },
      {
        path: 'faq',
        canActivate: [OnlyAfGuard],
        loadChildren: () =>
          import('./views/page-faq/page-faq.module').then(
            m => m.PageFaqModule
          )
      },
      {
        path: 'banners',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/contents/page-banners/page-banners.module').then(
            m => m.PageBannersModule
          )
      },
      {
        path: 'af-faq',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/contents/page-affaq/page-affaq.module').then(
            m => m.PageAffaqModule
          )
      },
      {
        path: 'alerts',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/contents/page-alerts/page-alerts.module').then(
            m => m.PageAlertsModule
          )
      },
      {
        path: 'articles',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/contents/page-posts/page-posts.module').then(
            m => m.PagePostsModule
          )
      },
      {
        path: 'categories',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import(
            './views/contents/page-categories/page-categories.module'
          ).then(m => m.PageCategoriesModule)
      },
      {
        path: 'operations',
        canActivate: [OnlyAdminOrSuperGuard],
        loadChildren: () =>
          import('./views/operations/page-operations.module').then(
            m => m.PageUserSettingsModule
          )
      },
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            m => m.DashboardModule
          )
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then(
            m => m.NotificationsModule
          )
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule],
  providers: [
    OnlyLogoutGuard,
    OnlyLoggedGuard,
    OnlyAdminOrSuperGuard,
    OnlyAfGuard
  ]
})
export class AppRoutingModule {}
