import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SignupService } from '@cms/shared/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  providers: [SignupService]
})
export class RegisterComponent {
  form: FormGroup;
  constructor(private fb: FormBuilder, private signup: SignupService) {
    this.form = this.fb.group({
      username: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      repassword: [null, [Validators.required]]
    });
  }

  register() {
    this.signup.create(this.form.value);
  }
}
