import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@apps/api-interfaces';
import { StorageService } from '@apps/ui';
import { Observable, of } from 'rxjs';
import { tap, mapTo, catchError } from 'rxjs/operators';

interface Tokens {
  access_token: string;
  refresh_token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;

  get isRoleAdminSuper(): boolean {
    return !!this.getLoggedUserRoles().super;
  }

  get isRoleAdmin(): boolean {
    return !!this.getLoggedUserRoles().admin;
  }

  get isRoleAf(): boolean {
    return !!this.getLoggedUserRoles().af;
  }

  constructor(private http: HttpClient, private storage: StorageService) {}

  login(user: { email: string; password: string }): Observable<boolean> {
    const url = `${API.AUTH}/login`;
    return this.http.post<any>(url, user).pipe(
      tap(tokens => this.doLoginUser(user.email, tokens)),
      mapTo(true),
      catchError(error => {
        return of(false);
      })
    );
  }

  logout() {
    this.doLogoutUser();
    return of(true);

    // const url = `${API.AUTH}/logout`;
    // return this.http
    //   .post<any>(url, {
    //     refresh_token: this.getRefreshToken()
    //   })
    //   .pipe(
    //     tap(() => this.doLogoutUser()),
    //     mapTo(true),
    //     catchError(error => {
    //       alert(error.error);
    //       return of(false);
    //     })
    //   );
  }

  getJwtToken(): string {
    return this.storage.getItem(this.JWT_TOKEN);
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    const url = `${API.AUTH}/refresh`;
    return this.http
      .post<any>(url, {
        refreshToken: this.getRefreshToken()
      })
      .pipe(
        tap((tokens: Tokens) => {
          this.storeJwtToken(tokens.access_token);
        })
      );
  }

  getLoggedUserRoles() {
    const data = this.parseJwt(this.getJwtToken());
    return (data && data.roles) || {};
  }

  getLoggedUserName() {
    const data = this.parseJwt(this.getJwtToken());
    return data && data.email;
  }

  getLoggedUserCID() {
    const data = this.parseJwt(this.getJwtToken());
    return data && data.username;
  }

  getLoggedUserId() {
    const data = this.parseJwt(this.getJwtToken());
    return data && data.sub;
  }

  doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private doLoginUser(email: string, tokens: Tokens) {
    this.loggedUser = email;
    this.storeTokens(tokens);
  }

  private getRefreshToken() {
    return this.storage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    this.storage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    this.storage.setItem(this.JWT_TOKEN, tokens.access_token);
    this.storage.setItem(this.REFRESH_TOKEN, tokens.refresh_token || '');
  }

  private removeTokens() {
    this.storage.removeItem(this.JWT_TOKEN);
    this.storage.removeItem(this.REFRESH_TOKEN);
  }

  private parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
