import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable, Inject, isDevMode } from '@angular/core';

import { InjectionToken } from '@angular/core';

export const SENTRY_DSN = new InjectionToken('SENTRY_DSN');

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(@Inject(SENTRY_DSN) dsn: string) {
    if (!dsn) {
      throw new Error('Inject SENTRY_DSN in app.module');
    }
    if (!isDevMode()) {
      Sentry.init({ dsn, environment: 'production' });
    }
    // Sentry.captureException(new Error('TEST Error'));
  }
  handleError(error) {
    // if (typeof error !== 'string' || error.indexOf('status":404') !== -1) {
    //   const eventId = Sentry.captureException(error.originalError || error);
    //   Sentry.showReportDialog({ eventId });
    // }
    return error;
  }
}
