import {
    trigger,
    state,
    animate,
    transition,
    style,
    AnimationMetadata,
} from '@angular/animations';

export const fadeAnimation: AnimationMetadata = trigger('fade', [
    state(
        '*',
        style({
            opacity: 1,
        })
    ),
    transition(':enter', [
        style({
            opacity: 0,
        }),
        animate(
            '200ms ease-in-out',
            style({
                opacity: 1,
            })
        ),
    ]),
    transition(':leave', [
        style({
            opacity: 1,
        }),
        animate(
            '150ms ease-in-out',
            style({
                opacity: 0,
            })
        ),
    ]),
]);
