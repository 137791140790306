import { MongoDbItem } from './mongo.model';
import { ProductType } from './products.model';

export const FILTER_ICONS = [
  { id: 'list', label: 'Lista' },

  { id: 'roof-white', label: 'roof-white' },
  { id: 'diamond', label: 'diamond' },
  { id: 'roof-small', label: 'roof-small' },
  { id: 'performance1', label: 'performance1' },
  { id: 'roof-black', label: 'roof-black' },
  { id: 'question1', label: 'question1' },
  { id: 'audit2', label: 'audit2' },
  { id: 'limited-stock', label: 'limited-stock' },
  { id: 'label', label: 'label' },
  { id: 'performance', label: 'performance' },
  { id: 'check-in', label: 'check-in' },
  { id: 'safety', label: 'safety' },
  { id: 'download', label: 'download' },
  { id: 'down-arrow', label: 'down-arrow' },
  { id: 'upload', label: 'upload' },
  { id: 'photo', label: 'photo' },
  { id: 'trash', label: 'trash' },
  { id: 'error', label: 'error' },
  { id: 'tick', label: 'tick' },
  { id: 'star', label: 'star' },
  { id: 'close', label: 'close' },
  { id: 'arrow', label: 'arrow' },
  { id: 'help', label: 'help' },
  { id: 'question', label: 'question' },
  { id: 'info', label: 'info' },
  { id: 'mail1', label: 'mail1' },
  { id: 'telephone', label: 'telephone' },
  { id: 'reward', label: 'reward' },
  { id: 'award', label: 'award' },
  { id: 'first', label: 'first' },
  { id: 'bill', label: 'bill' },
  { id: 'money', label: 'money' },
  { id: 'light', label: 'light' },
  { id: 'interest', label: 'interest' },
  { id: 'year', label: 'year' },
  { id: 'minus', label: 'minus' },
  { id: 'plus', label: 'plus' },
  { id: 'guarantee', label: 'guarantee' },
  { id: 'audyt', label: 'audyt' },
  { id: 'formalnosci', label: 'formalnosci' },
  { id: 'gielda', label: 'gielda' },
  { id: 'wsparcie', label: 'wsparcie' }
];

export class FilterItem {
  name: string;
  title: string;
  icon: string;
  description: string;
  order: number;
  filterIds?: string;
  content: string;
  product?: ProductType[];
}

export interface DbFilterItem extends FilterItem, MongoDbItem {}
