import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivationEnd } from '@angular/router';
import { takeUntil, filter, map } from 'rxjs/operators';
import { Destroyer } from '@apps/tools';

export const TITLE_PREFIX = new InjectionToken('TITLE_PREFIX', {
  providedIn: 'root',
  factory: (): string => ''
});

@Injectable({
  providedIn: 'root'
})
export class TitleService extends Destroyer {
  constructor(
    private router: Router,
    private title: Title,
    @Inject(TITLE_PREFIX) private prefix
  ) {
    super();
  }

  async setTitle(title: string) {
    this.title.setTitle(`${this.prefix} - ${title}`);
  }

  public initRouterTitles(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e instanceof ActivationEnd),
        filter((e: ActivationEnd) => !!e.snapshot.data.title),
        map(e => e.snapshot.data.title)
      )
      .subscribe((title: string) => {
        this.setTitle(title);
      });
  }
}
