import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export class Destroyer implements OnDestroy {
    protected destroy$: Subject<boolean> = new Subject<boolean>();

    destroy() {}

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.destroy();
    }
}
