/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./500.component";
var styles_P500Component = [];
var RenderType_P500Component = i0.ɵcrt({ encapsulation: 2, styles: styles_P500Component, data: {} });
export { RenderType_P500Component as RenderType_P500Component };
export function View_P500Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "app flex-row align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [["class", "float-left display-3 mr-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["500"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [["class", "pt-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Houston, we have a problem!"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The page you are looking for is temporarily unavailable."]))], null, null); }
export function View_P500Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_P500Component_0, RenderType_P500Component)), i0.ɵdid(1, 49152, null, 0, i1.P500Component, [], null, null)], null, null); }
var P500ComponentNgFactory = i0.ɵccf("ng-component", i1.P500Component, View_P500Component_Host_0, {}, {}, []);
export { P500ComponentNgFactory as P500ComponentNgFactory };
