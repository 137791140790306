/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../../../libs/ui/src/lib/forms/form/form-submit.directive";
import * as i3 from "@angular/router";
import * as i4 from "../../_shared/services/signup.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./register.component";
var styles_RegisterComponent = [];
var RenderType_RegisterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
export function View_RegisterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "div", [["class", "app-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 23, "main", [["class", "main d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 22, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 21, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 20, "div", [["class", "col-md-6 mx-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 19, "div", [["class", "card mx-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 18, "div", [["class", "card-body p-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 17, "form", [["novalidate", ""], ["sc-form", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(9, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(11, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), i0.ɵdid(12, 16384, null, 0, i2.FormSubmitDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(13, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Rejestracja"])), (_l()(), i0.ɵeld(15, 0, null, null, 6, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Aby do\u0142\u0105czy\u0107 do programu partnerskeigo skontaktuj si\u0119 z nami na adres: partnerzy@zatrzymajlicznik.pl."])), (_l()(), i0.ɵeld(17, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Otw\u00F3rz ten "])), (_l()(), i0.ɵeld(19, 0, null, null, 1, "a", [["href", "https://zatrzymajlicznik.pl/images/partnerzy.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Dokument PDF"])), (_l()(), i0.ɵted(-1, null, [" by zapozna\u0107 si\u0119 z podstawowymi informacjami o warunkacj wsp\u00F3\u0142pracy "])), (_l()(), i0.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-block btn-success"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Strona logowania"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 9, 0, currVal_7); var currVal_8 = "/login"; _ck(_v, 23, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 11).ngClassValid; var currVal_5 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_RegisterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-dashboard", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i0.ɵprd(512, null, i4.SignupService, i4.SignupService, [i5.HttpClient]), i0.ɵdid(2, 49152, null, 0, i6.RegisterComponent, [i1.FormBuilder, i4.SignupService], null, null)], null, null); }
var RegisterComponentNgFactory = i0.ɵccf("app-dashboard", i6.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };
