const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
// Import containers
import { DefaultLayoutComponent } from './containers';
import { FilterMenuPipe } from './containers/default-layout/filter-menu.pipe';
const APP_CONTAINERS = [DefaultLayoutComponent, FilterMenuPipe];
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { environment } from '../environments/environment';
registerLocaleData(localePl, 'pl');
const ɵ0 = () => 'scp', ɵ1 = environment.sentry;
export class AppModule {
}
export { ɵ0, ɵ1 };
