import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Destroyer } from '@apps/tools';
import { SessionService } from '../services/session.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/session.service";
export class TokenInterceptor extends Destroyer {
    constructor(session) {
        super();
        this.session = session;
        this.isRefreshing = false;
        this.refreshTokenSubject = new BehaviorSubject(null);
    }
    intercept(request, next) {
        if (this.session.getJwtToken()) {
            request = this.addToken(request, this.session.getJwtToken());
        }
        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            }
            else {
                return throwError(error);
            }
        }));
    }
    addToken(request, token) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    handle401Error(request, next) {
        this.session.doLogoutUser();
        return next.handle(this.addToken(request, ''));
        // if (!this.isRefreshing) {
        //   this.isRefreshing = true;
        //   this.refreshTokenSubject.next(null);
        //   return this.session.refreshToken().pipe(
        //     switchMap((token: any) => {
        //       this.isRefreshing = false;
        //       this.refreshTokenSubject.next(token.jwt);
        //       return next.handle(this.addToken(request, token.jwt));
        //     })
        //   );
        // } else {
        //   return this.refreshTokenSubject.pipe(
        //     filter(token => token != null),
        //     take(1),
        //     switchMap(jwt => {
        //       return next.handle(this.addToken(request, jwt));
        //     })
        //   );
        // }
    }
}
TokenInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenInterceptor_Factory() { return new TokenInterceptor(i0.ɵɵinject(i1.SessionService)); }, token: TokenInterceptor, providedIn: "root" });
