import { MongoDbItem } from './mongo.model';
import { FilterItem } from './filter.model';
import { ProviderItem } from './providers.model';

export type ProductType = 'prad' | 'gaz' | 'fotowoltaika';

export const PRODUCT_TYPES = [
  { id: 'prad', label: 'Prąd', label1: 'prądu', label2: 'prądem', unit: 'MWh' },
  { id: 'gaz', label: 'Gaz', label1: 'gazu', label2: 'gazem', unit: 'MWh' },
  {
    id: 'fotowoltaika',
    label: 'Fotowoltaika',
    label1: 'fotowoltaiki',
    label2: 'fotowoltaiką',
    unit: 'KW'
  },
  {
    id: 'audyty',
    label: 'Audyt energetyczny',
    label1: 'audytów',
    label2: 'audytem'
  }
];
export const PERIOD_DEFAULT = '3y';
export const CONTRACT_START_DEFAULT = '4';

export const ENERGY_DEFAULT_TARIFF = 'c11';
export const ENERGY_TARIFFES = [
  { id: 'g11', label: 'G11' },
  { id: 'g12', label: 'G12', flat: 'g11' },
  { id: 'g12w', label: 'G12w' },
  { id: 'c11', label: 'C11' },
  { id: 'c21', label: 'C21' },
  { id: 'c23', label: 'C23' },
  {
    id: 'b21',
    label: 'B21',
    flat: 'b21',
    description: 'minimum 50MWh/rok',
    extra: 50
  },
  {
    id: 'b22',
    label: 'B22',
    flat: 'b21',
    description: 'minimum 50MWh/rok',
    extra: 50
  },
  {
    id: 'b23',
    label: 'B23',
    flat: 'b21',
    description: 'minimum 50MWh/rok',
    extra: 50
  },
  { id: 'c12a', label: 'C12a', flat: 'c11' },
  { id: 'c12b', label: 'C12b' },
  { id: 'c12w', label: 'C12w' },
  { id: 'c22a', label: 'C22a', flat: 'c21' },
  { id: 'c22b', label: 'C22b' }
];

export const GAS_DEFAULT_TARIFF = 'w-3';
export const GAS_TARIFFES = [
  // { id: 'w-1', label: 'W-1' },
  // { id: 'w-2', label: 'W-2' },
  { id: 'w-3', label: 'W-3.6 / W-3.9' },
  // { id: 'w-4', label: 'W-4', description: 'minimum 200MWh/rok', extra: 200 },
  { id: 'w-4', label: 'W-4' },
  { id: 'w-5', label: 'W-5' },
  { id: 'w-6', label: 'W-6' }
];

export class ProductItem {
  id: string;
  provider: ProviderItem;
  attributes: FilterItem[];
  region: Region;
  tariff: string;
  flat?: boolean;
  green?: boolean;
  saving?: number;
  period?: string;
  promotion?: string;
  bestOffer?: boolean;
  bestPrice?: boolean;
  product?: ProductType;
  paused?: boolean;
  mostPopular?: boolean;
  zones?: number;
  summary?: {
    ourPrice: string;
    depPrice: string;
    percent?: number;
    from?: boolean;
    totalSavings?: number;
    priceTooltip?: string;
    savingTooltip?: string;
    agreementStartDate?: string;
    agreementEndDate?: string;
  };
  amount: any;
  fee?: any;
  // amount: {
  //   our: number[];
  //   dep: number[] | null;
  // };
  // fee?: {
  //   fv: number;
  //   efv: number;
  // };
}

export interface DbProductItem extends ProductItem, MongoDbItem {}

export interface CalcOfferItem {
  provider: string;
  rate: string;
  order: number;
}

export interface Price {
  startAt: string;
  title: string;
  dealer: string;
  type: string;
  active?: boolean;
  general: boolean;
  basic?: boolean;
  parentDealer?: string;
  delta?: number;
  description?: string;
  promotion?: string;
  product?: ProductType;
  activeManually?: boolean;
  year?: number;
  items: {
    tariff: string;
    prices: {
      zone1: number;
      zone2?: number;
      zone3?: number;
    };
    tradeFee: {
      fv: number;
      efv: number;
    };
  }[];
}

export interface DbPrice extends Price, MongoDbItem {}

export interface Region {
  zip: string;
  city: string;
  provider: string;
  address: string;
  province: string;
  state: string;
}

export interface DbRegion extends Region, MongoDbItem {}
