import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: () => `Pole jest wymagane`,
  requiredTrue: () => `Wymagana akceptacja`,
  email: () => `Błędny adres e-mail`,
  emailFormat: () => `Błędny adres e-mail`,
  phoneNumber: () => `Błędny numer telefonu`,
  zipCode: () => `Błędny kod pocztowy`,
  minlength: ({ requiredLength, actualLength }) =>
    `Minialna ilość znaków to ${requiredLength} a podano ${actualLength}`
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
