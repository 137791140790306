import { Injectable, Inject, InjectionToken } from '@angular/core';

export const STORAGE_PREFIX = new InjectionToken('STORAGE_PREFIX', {
  providedIn: 'root',
  factory: (): string => 'app'
});

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(@Inject(STORAGE_PREFIX) private prefix) {}

  public getItem(name: string, session = false): string {
    return this.convertFromJSON(
      (session ? sessionStorage : localStorage).getItem(
        `${this.prefix}_${name}`
      )
    );
  }

  public setItem(name: string, value: any, session = false): void {
    (session ? sessionStorage : localStorage).setItem(
      `${this.prefix}_${name}`,
      this.convertToString(value)
    );
  }

  public removeItem(name: string, session = false): void {
    (session ? sessionStorage : localStorage).removeItem(
      `${this.prefix}_${name}`
    );
  }

  private convertToString(data: any): string {
    switch (typeof data) {
      case 'object':
        return JSON.stringify(data);
      case 'string':
        return data;
      default:
        return data.toString();
    }
  }

  private convertFromJSON(data: any): string {
    if (!data || !data[0] || !['{', '['].includes(data[0])) {
      return data;
    }

    return JSON.parse(data);
  }
}
