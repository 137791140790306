import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}

  public transform(value: number, symbol: boolean = true): string {
    if (!Number.isFinite(+value)) {
      return `${value}`;
    }
    const _symbol = symbol ? 'symbol-narrow' : false;
    return this.currency.transform(value, 'PLN', _symbol, '1.2-2');
  }
}
