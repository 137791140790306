import { Pipe, PipeTransform } from '@angular/core';
import { INavData } from '@coreui/angular';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'filterMenu',
  pure: false
})
export class FilterMenuPipe implements PipeTransform {
  transform(array: INavData[], roles): INavData[] {
    if (!array || !environment.production) {
      return array;
    }

    const classes = Object.entries(roles)
      .filter(e => e[1] === true)
      .map(e => `-${e[0]}-`);

    return array.filter(
      e => !e.icon || e.icon.split(' ').findIndex(f => classes.includes(f)) > -1
    );
  }
}
