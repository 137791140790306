import { Component } from '@angular/core';
import { SessionService } from '@cms/shared/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Destroyer } from '@apps/tools';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends Destroyer {
  form: FormGroup;
  error = false;

  constructor(
    private fb: FormBuilder,
    private session: SessionService,
    private router: Router
  ) {
    super();
    this.form = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.error = false;
    });
  }

  login() {
    if (!this.form.valid) {
      return;
    }

    this.session
      .login(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(success => {
        if (success) {
          this.router.navigate(['/dashboard']);
        } else {
          this.error = true;
        }
      });
  }
}
