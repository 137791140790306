import {
  trigger,
  animate,
  transition,
  style,
  AnimationMetadata
} from '@angular/animations';

export const slideAnimations: AnimationMetadata = trigger('slide', [
  transition(
    ':leave',
    [
      style({
        opacity: 1,
        transform: 'translate{{ axis }}(0{{ util }})'
      }),
      animate(
        '{{ duration }} {{ delay }}ms cubic-bezier(0.35, 0, 0.25, 1)',
        style({
          opacity: '{{ opacity }}',
          transform: 'translate{{ axis }}({{ width }}{{ util }})'
        })
      )
    ],
    {
      params: {
        duration: '0.3s',
        width: -100,
        delay: 0,
        opacity: 0,
        util: '%',
        axis: 'X'
      }
    }
  ),
  transition(
    ':enter',
    [
      style({
        opacity: '{{ opacity }}',
        transform: 'translate{{ axis }}({{ width }}{{ util }})'
      }),
      animate(
        '{{ duration }} {{ delay }}ms cubic-bezier(0.35, 0, 0.25, 1)',
        style({
          opacity: 1,
          transform: 'translate{{ axis }}(0{{ util }})'
        })
      )
    ],
    {
      params: {
        duration: '0.3s',
        width: -100,
        delay: 0,
        opacity: 0,
        util: '%',
        axis: 'X'
      }
    }
  )
]);

export const slideInAnimations: AnimationMetadata = trigger('slideIn', [
  transition(
    ':enter',
    [
      style({
        opacity: '{{ opacity }}',
        transform: 'translate{{ axis }}({{ width }}{{ util }})'
      }),
      animate(
        '{{ duration }} {{ delay }}ms cubic-bezier(0.35, 0, 0.25, 1)',
        style({
          opacity: 1,
          transform: 'translate{{ axis }}(0{{ util }})'
        })
      )
    ],
    {
      params: {
        duration: '0.3s',
        width: -100,
        delay: 0,
        opacity: 0,
        util: '%',
        axis: 'X'
      }
    }
  )
]);
