import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivationEnd } from '@angular/router';
import { Destroyer } from '@apps/tools';
import { takeUntil, filter, map, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

export type MetadataTag =
  | 'url'
  | 'type'
  | 'title'
  | 'description'
  | 'image'
  | 'canonical'
  | 'robots'
  | 'site_name'
  | string;
export interface Metadata {
  name?: MetadataTag;
  content?: string;
  force?: boolean;
}

export interface MetadataDefault {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  site_name?: string;
  origin_domain?: string;
}

export const METADATA_GLOBAL = new InjectionToken('METADATA_GLOBAL', {
  providedIn: 'root',
  factory: () => {}
});

/**
 * Example:
 * this._meta.update([
 *  {name: `title`, content: `Upcoming Competition`},
 *  {name: `description`, content: this.submitToCompetition.title},
 *  {name: `url`, content: this._router.url},
 * ]);
 */

@Injectable({
  providedIn: 'root'
})
export class MetadataService extends Destroyer {
  private defaults: MetadataDefault;
  private domain: string;
  private tags: MetadataTag[] = [
    'url',
    'type',
    'title',
    'description',
    'image',
    'canonical',
    'robots',
    'site_name'
  ];

  constructor(
    @Inject(METADATA_GLOBAL) globalData,
    @Inject(DOCUMENT) private dom,
    private meta: Meta,
    private router: Router,
    private title: Title,
  ) {
    super();
    this.defaults = globalData || {};
    this.setBaseDomain();
  }

  public update(data: Metadata[]) {
    for (const tag of this.tags) {
      const tagdata = data[tag];
      const force = tagdata && tagdata.force;
      this._update(tag, tagdata, force);
    }
  }

  public initRouterUpdateMeta(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        // tap(e => console.log(e)),
        filter(e => e instanceof ActivationEnd),
        filter((e: ActivationEnd) => !!e.snapshot.data),
        map(e => e.snapshot.data)
      )
      .subscribe(({ title, description, post, banner }) => {
        this.setPageMeta(post, banner, title, description);
      });
  }

  public getMeta(name: string): string {
    if (name !== 'canonical') {
      const meta = this.meta.getTag(`property="${name}"`);
      return meta && meta.content;
    } else {
      return this.getCanonicalURL();
    }
  }

  private setPageMeta(post, banner, title, description) {
    const currentUrl = `${this.domain}${this.router.url}`;
    if (currentUrl === this.getMeta('canonical')) {
      return;
    }

    if (post) {
      this.updateTitle(post.title);
      this._update('description', { content: post.excerpt || post.content || post.title });
      this._update('image', { content: post.image });
      this._update('canonical', { content: currentUrl });
    } else if (banner) {
      this._update('title', { content: banner.title });
      this._update('description', { content: banner.content || banner.title });
      this._update('image', { content: banner.image });
      this._update('canonical', { content: currentUrl });
      this.updateTitle(banner.title);
    } else if (title || description) {
      this._update('title', { content: title });
      this._update('description', { content: description || title });
      this._update('canonical', { content: currentUrl });
      this.updateTitle(title);
    } else {
      this.updateTitle();
    }
  }

  private updateTitle(title?: string) {
    let content = '';
    if (!title || typeof title !== 'string') {
      content = `${this.defaults.site_name} - Wirtualny broker energii`;
    } else {
      const sufix = ` | ${ this.defaults.site_name }`;
      if (title.length + sufix.length > 70) {
        content = title;
      } else {
        content = `${title}${sufix}`;
      }
    }

    this.title.setTitle(content);
    this.meta.updateTag({
      property: `og:title`,
      content: content.replace(/<\/?[^>]+(>|$)/g, '')
    });
    this.meta.updateTag({
      property: `twitter:title`,
      content: content.replace(/<\/?[^>]+(>|$)/g, '')
    });
    
  }

  private _update(tag: MetadataTag, data: Metadata, remove: boolean = false) {
    const content = data['content'] || this.defaults[tag];

    switch (tag) {
      case 'image':
        if (!remove) {
          this.meta.updateTag({
            property: `og:${tag}`,
            content: `${this.domain}${this.getImageSocial(content)}`
          });
          this.meta.updateTag({
            property: `og:${tag}:secure_url`,
            content: `${this.domain}${this.getImageSocial(content)}`
          });
          this.meta.updateTag({
            property: `twitter:${tag}`,
            content: `${this.domain}${this.getImageSocial(content)}`
          });
          this.meta.updateTag({ property: `og:image:width`, content: '1200' });
          this.meta.updateTag({ property: `og:image:height`, content: '1200' });
        } else {
          this.meta.updateTag({
            property: `og:${tag}`,
            content: ``
          });
          this.meta.updateTag({
            property: `og:${tag}:secure_url`,
            content: ``
          });
          this.meta.updateTag({
            property: `twitter:${tag}`,
            content: ``
          });
          this.meta.updateTag({ property: `og:image:width`, content: '' });
          this.meta.updateTag({ property: `og:image:height`, content: '' });
        }
        break;
      case 'title':
        // if (!remove && data.content) {
        //   this.meta.updateTag({
        //     property: `og:${tag}`,
        //     content: content.replace(/<\/?[^>]+(>|$)/g, '')
        //   });
        //   this.meta.updateTag({
        //     property: `twitter:${tag}`,
        //     content: content.replace(/<\/?[^>]+(>|$)/g, '')
        //   });
        // } else {
        //   this.meta.updateTag({
        //     property: `og:${tag}`,
        //     content: ''
        //   });
        //   this.meta.updateTag({
        //     property: `twitter:${tag}`,
        //     content: ''
        //   });
        // }
        break;
      case 'description':
        if (!remove && data.content) {
          this.meta.updateTag({
            name: `${tag}`,
            content: content.replace(/<\/?[^>]+(>|$)/g, '')
          });
          this.meta.updateTag({
            property: `og:${tag}`,
            content: content.replace(/<\/?[^>]+(>|$)/g, '')
          });
          this.meta.updateTag({
            property: `twitter:${tag}`,
            content: content.replace(/<\/?[^>]+(>|$)/g, '')
          });
        } else {
          this.meta.updateTag({
            name: `${tag}`,
            content: ''
          });
          this.meta.updateTag({
            property: `og:${tag}`,
            content: ''
          });
          this.meta.updateTag({
            property: `twitter:${tag}`,
            content: ''
          });
        }
        break;
      case 'type':
        if (!remove) {
          this.meta.updateTag({
            property: `og:${tag}`,
            content
          });
        } else {
          this.meta.removeTag(`property='og:${tag}'`);
        }
        break;
      case 'canonical':
        if (data.content) {
          if (!remove) {
            this.createCanonicalURL(data.content);
            this.meta.updateTag({
              property: 'og:url',
              content: `${data.content}`
            });
          } else {
            this.meta.removeTag('property=og:url');
            this.removeCanonicalURL();
          }
        }
        break;
    }
  }

  private createCanonicalURL(url: string) {
    const el = this.dom.querySelector('link[rel="canonical"]');
    if (!el) {
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', url);
    } else {
      el.setAttribute('href', url);
    }
  }

  private removeCanonicalURL() {
    const el = this.dom.querySelector('link[rel="canonical"]');
    if (el) {
      el.remove();
    }
  }

  private getCanonicalURL() {
    const el = this.dom.querySelector('link[rel="canonical"]');
    if (el) {
      return el.getAttribute('href');
    }
    return `${this.domain}${this.router.url}`;
  }

  private getImageSocial(img: string): string {
    const name = this.getFileName(img);
    const ext = this.getFileExtension(img);
    return `${name}_social.${ext}`;
  }

  private getFileName(filename): string {
    return filename
      .split('.')
      .slice(0, -1)
      .join('.');
  }

  private getFileExtension(filename): string {
    return filename.split('.').pop();
  }

  private setBaseDomain() {
    // if (window.location.origin && window.location.origin !== 'null') {
    //   this.domain = window.location.origin;
    // } else {
    this.domain = this.defaults.origin_domain;
    // }
  }
}
