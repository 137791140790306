import {
    trigger,
    state,
    animate,
    transition,
    style,
    AnimationMetadata,
    animateChild,
    query,
} from '@angular/animations';
import { fadeAnimation } from './fade.animation';
import { scaleAnimation } from './scale.animation';

export const toggleHeightAnimation: AnimationMetadata[] = [
    trigger('toggleHeight', [
        state(':enter', style({ opacity: 0.0, height: 0 })),
        state(':leave', style({ opacity: 1.0, height: '*' })),
        transition(
            ':enter',
            [
                style({ opacity: 0.0, height: 0 }),
                animate(
                    '{{ duration }} cubic-bezier(0.35, 0, 0.25, 1)',
                    style({ opacity: 1.0, height: '*' })
                ),
                query('@fade', animateChild(), { optional: true }),
                query('@scale', animateChild(), { optional: true }),
            ],
            {
                params: { duration: '0.6s' },
            }
        ),
        transition(
            ':leave',
            [
                // query('@fade', animateChild(), { optional: true }),
                // query('@scale', animateChild(), { optional: true }),
                style({ opacity: 1.0, height: '*' }),
                animate(
                    '{{ duration }} cubic-bezier(0.35, 0, 0.25, 1)',
                    style({ opacity: 0.0, height: 0 })
                ),
            ],
            {
                params: { duration: '0.1s' },
            }
        ),
    ]),
    fadeAnimation,
    scaleAnimation,
];
