import { Directive, Input, ElementRef } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'form[sc-form]'
})
export class FormSubmitDirective {
  @Input() scroll = true;

  submit$ = fromEvent(this.element, 'submit').pipe(
    tap(() => {
      if (this.element.classList.contains('submitted') === false) {
        this.element.classList.add('submitted');
      }
      this.scrollToFirstError();
    })
  );

  unsubmit$: Subject<undefined> = new Subject();

  get element() {
    return this.el.nativeElement;
  }

  constructor(private el: ElementRef<HTMLFormElement>) {}

  resetSubmit() {
    if (this.element.classList.contains('submitted') === true) {
      this.element.classList.remove('submitted');
    }
  }

  private scrollToFirstError(): void {
    if (!this.scroll || !this.element) {
      return;
    }
    const invalids = this.element.querySelector(
      'form.submitted .odl-input.ng-invalid'
    );

    if (invalids) {
      // @TODO
    }
  }
}
