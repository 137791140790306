import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { SafePipe, PricePipe, SortPipe } from '@apps/ui';
import { ClickOutsideDirective } from './directives/clickOutside.directive';

const pipes = [SafePipe, PricePipe, SortPipe, ClickOutsideDirective];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule, HttpClientModule],
  exports: [...pipes],
  providers: [CurrencyPipe]
})
export class SharedModule {}
