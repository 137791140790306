import { Injectable, InjectionToken, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare var grecaptcha: any;

export const RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js?render=';
export const RECAPTCHA_PUBLIC_KEY = new InjectionToken('RECAPTCHA_PUBLIC_KEY', {
  providedIn: 'root',
  factory: () => ''
});

@Injectable()
export class CaptchaService {
  grecaptcha: any;
  url: string;

  private readySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  ready$ = this.readySubject.asObservable();

  constructor(@Inject(RECAPTCHA_PUBLIC_KEY) private PUBLIC_KEY) {}

  get isReady(): boolean {
    return this.readySubject.getValue();
  }

  async load() {
    this.url = `${RECAPTCHA_URL}${this.PUBLIC_KEY}`;
    return new Promise(async resolve => {
      const script: any = document.createElement(`script`);
      script.type = 'text/javascript';
      script.onload = () => {
        grecaptcha.ready(() => {
          this.grecaptcha = grecaptcha;
          this.readySubject.next(true);
          resolve();
        });
      };
      script.src = this.url;
      document.body.appendChild(script);
    });
  }

  async execute(): Promise<string> {
    return await grecaptcha.execute(this.PUBLIC_KEY);
  }

  destroy() {
    try {
      document.querySelector(`script[src="${this.url}"]`).remove();
      const badge = document.querySelector(`.grecaptcha-badge`);
      badge.parentElement.remove();
    } catch (e) {}

    this.grecaptcha = undefined;
    this.url = undefined;
    this.readySubject.next(false);
  }
}
