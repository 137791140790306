export const PROMOTION_TYPES = [
  {
    id: 'black-friday',
    label: 'Black friday',
    labelText: 'BLACK WEEK'
  },
  {
    id: 'pump-free',
    label: 'Pompa ciepła gratis',
    labelText: 'Pompa ciepła<br>GRATIS!'
  },
  {
    id: 'two-phase-oven',
    label: 'Piec dwufunkcyjny gratis',
    labelText: 'GRATIS! Piec<br>dwufunkcyjny'
  },
  {
    id: 'holy',
    label: 'Promocja świąteczna',
    labelText: 'Promocja<br>świąteczna'
  }
];

export const PROMOTION_EXTRAS = [
  {
    id: 'christmas-lights',
    label: 'Lampki świąteczne'
  }
];

// https://codepen.io/mark_sottek/pen/WrNqJV