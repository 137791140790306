import * as Sentry from '@sentry/browser';
import { ErrorHandler, isDevMode } from '@angular/core';
import { InjectionToken } from '@angular/core';
import * as i0 from "@angular/core";
export const SENTRY_DSN = new InjectionToken('SENTRY_DSN');
export class SentryErrorHandler {
    constructor(dsn) {
        if (!dsn) {
            throw new Error('Inject SENTRY_DSN in app.module');
        }
        if (!isDevMode()) {
            Sentry.init({ dsn, environment: 'production' });
        }
        // Sentry.captureException(new Error('TEST Error'));
    }
    handleError(error) {
        // if (typeof error !== 'string' || error.indexOf('status":404') !== -1) {
        //   const eventId = Sentry.captureException(error.originalError || error);
        //   Sentry.showReportDialog({ eventId });
        // }
        return error;
    }
}
SentryErrorHandler.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentryErrorHandler_Factory() { return new SentryErrorHandler(i0.ɵɵinject(SENTRY_DSN)); }, token: SentryErrorHandler, providedIn: "root" });
