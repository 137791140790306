import { AbstractControl } from '@angular/forms';

export const validatorEmailFormat = (control: AbstractControl) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(String(control.value).toLowerCase());

  if (control.value && !valid) {
    return { emailFormat: true };
  }
  return null;
};
