import { ProductType } from './products.model';
import { MongoDbItem } from './mongo.model';
import { CategoryItem } from './categories.model';

export type PostType =
  | 'post'
  | 'testimonial'
  | 'faq'
  | 'info'
  | 'tarif'
  | 'other'
  | 'alert'
  | 'af_faq'
  | 'g-price-list'
  | 'g-city-offer'
  | 'g-city-operator'
  | 'g-city-provider';

export const POST_TYPES = [
  { id: 'post', label: 'Artykuł do bloga' },
  { id: 'testimonial', label: 'Referencja klienta' },
  { id: 'faq', label: 'Pytania i odpowiedzi' },
  // { id: 'info', label: 'Informacje' },
  // { id: 'tarif', label: 'Taryfa' },
  { id: 'other', label: 'Tekst statyczny' },
  // { id: 'g-price-list', label: 'Generyczny: opis cennika' },
  // { id: 'g-city-offer', label: 'Generyczny: oferta w mieście' },
  // { id: 'g-city-operator', label: 'Generyczny: operator w mieście' },
  // { id: 'g-city-provider', label: 'Generyczny: opis sprzedawcy' }
];

export const POST_SLUGS_CONSTS = [
  { id: 'newsletter', label: 'Regulamin newsletter' },
  { id: 'o-kalkulatorze-prad', label: 'Kalkulator opis - Prąd' },
  { id: 'o-kalkulatorze-gaz', label: 'Kalkulator opis - Gaz' },
  {
    id: 'o-kalkulatorze-fotowoltaika',
    label: 'Kalkulator opis - Fotowoltaika'
  },
  { id: 'o-dotacje-prad', label: 'Dotacje opis - Prąd' },
  { id: 'o-dotacje-gaz', label: 'Dotacje opis - Gaz' },
  {
    id: 'o-dotacje-fotowoltaika',
    label: 'Dotacje opis - Fotowoltaika'
  },
  { id: 'seo-text-1', label: 'SEO text 1' },
  { id: 'seo-text-2', label: 'SEO text 2' },
  { id: 'seo-text-3', label: 'SEO text 3' },
  { id: 'regulamin-zgloszenie-1', label: 'Regulamin zamów kontakt' },
  { id: 'regulamin-zgloszenie-2', label: 'Regulamin zamów kontakt' },

  { id: 'regulamin', label: 'Regulamin' },
  { id: 'polityka-prywatnosci', label: 'Polityka prywatności' },
  { id: 'pytania-i-odpowiedzi', label: 'Pytania i odpowiedzi' },
  { id: 'nasi-partnerzy', label: 'Nasi partnerzy' }
];

export class PostItem {
  title?: string;
  image?: string;
  slug?: string;
  excerpt?: string;
  content?: string;
  promo?: boolean;
  attachment?: string;
  category?: string;
  product?: ProductType[];
  type?: PostType;
  disabled?: boolean;
  extra?: any;
  extra2?: any;
  extra3?: any;
  extra4?: any;
  enddata?: string;
  categoryNames?: CategoryItem[];
}

export interface DbPostItem extends PostItem, MongoDbItem {}


// wordpress
export interface WpPostItem {
  id: number,
  date: string,
  slug: string,
  link: string;
  categories: number[];
  title: string;
  excerpt: string;
  image?: {
    '1536x1536': string;
    '2048x2048': string;
    large: string;
    medium: string;
    medium_large: string;
    td_150x0: string;
    td_218x150: string;
    td_300x0: string;
    td_324x160: string;
    td_324x235: string;
    td_324x400: string;
    td_485x360: string;
    td_534x462: string;
    td_696x0: string;
    td_1068x0: string;
    td_1920x0: string;
    thumbnail: string;
  }
}

export enum WpPostCategory {
  PRAD = 52,
  GAZ = 53,
  FOTOWOLTAIKA = 54
}
