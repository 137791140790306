import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { API } from '@apps/api-interfaces';
export class SignupService {
    constructor(http) {
        this.http = http;
    }
    create(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = API.USER;
            return yield this.http.post(url, data).toPromise();
        });
    }
}
