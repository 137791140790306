import * as i0 from "@angular/core";
const { detect } = require('detect-browser');
export class DetectDeviceService {
    get browser() {
        return detect();
    }
    get isSimulator() {
        return false; // window.location.hostname === 'localhost';
    }
    get userAgent() {
        return window.navigator.userAgent;
    }
    get isiOS() {
        return !!this.userAgent.match(/iPad/i) || !!this.userAgent.match(/iPhone/i);
    }
    get isWebkit() {
        return !!this.userAgent.match(/WebKit/i);
    }
    get isChrome() {
        return this.userAgent.indexOf("Chrome") > -1;
    }
    get isSafari() {
        return !this.isChrome && this.userAgent.indexOf("Safari") > -1;
    }
    setBodyData() {
        try {
            const inline = Object.values(this.browser).join('|');
            document.body.setAttribute('data-browser', inline);
        }
        catch (e) { }
    }
}
DetectDeviceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DetectDeviceService_Factory() { return new DetectDeviceService(); }, token: DetectDeviceService, providedIn: "root" });
