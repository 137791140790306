<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card mx-4">
            <div class="card-body p-4">
              <form sc-form [formGroup]="form">
                <h1>Rejestracja</h1>
                <p class="text-muted">
                  Aby dołączyć do programu partnerskeigo skontaktuj się z nami na adres: partnerzy@zatrzymajlicznik.pl.<br />
                  Otwórz ten <a href="https://zatrzymajlicznik.pl/images/partnerzy.pdf" target="_blank">Dokument PDF</a> by zapoznać się z podstawowymi informacjami o warunkacj współpracy
                </p>
                <button type="submit" [routerLink]="'/login'" class="btn btn-block btn-success">Strona logowania</button>
                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input type="text" formControlName="username" class="form-control" placeholder="Nazwa użytkownika" autocomplete="username" required>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">@</span>
                  </div>
                  <input type="text" formControlName="email" class="form-control" placeholder="Email" autocomplete="email" required>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" formControlName="password" class="form-control" placeholder="Hasło" autocomplete="new-password" required>
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" formControlName="repassword" class="form-control" placeholder="Powtórz hasło" autocomplete="new-password" required>
                </div>
                <button type="submit" (click)="register()" class="btn btn-block btn-success">Stwórz konto</button> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
