import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  public transform(items: any[], field: string = 'order', dest: 'asc' | 'desc' = 'desc', subkey = 'value'): any[] {
    if (!items || !items.length) {
      return items;
    }

    const target = dest === 'desc' ? 1 : -1;

    if (!subkey) {
      return items.sort((a, b) => {
        const f1 = Number.isFinite(a[field]) ? a[field] : 9999999;
        const f2 = Number.isFinite(b[field]) ? b[field] : 9999999;
        return f1 > f2 ? (-1 * target) : (1 * target);
      });
    }

    return items.sort((a, b) => {
      const f1 = Number.isFinite(a[subkey][field]) ? a[subkey][field] : 9999999;
      const f2 = Number.isFinite(b[subkey][field]) ? b[subkey][field] : 9999999;
      return f1 > f2 ? (-1 * target) : (1 * target);
    });
  }
}
