import * as moment from "moment";

export const GRANTS_LIST = [
  { 
      id: 'waw',
      label: 'Program Warszawa',
      description: 'max. 15.000 zł bezzwrotne. Nabór do 31.03.2021r.',
      end: moment('31-03-2021', 'DD-MM-YYYY'),
      fields: ['phone'],
      product: 'fotowoltaika'
  },
  { 
      id: 'moj_prad',
      label: 'Mój Prąd 2.0',
      description: 'Program ograniczony. Zarezerwujemy dla Ciebie pierwszy dostępny termin.',
      fields: ['phone', 'email', 'verify'],
      product: 'fotowoltaika'
  }
];
