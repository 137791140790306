export const generatetUuId = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0, // tslint:disable-line
      v = c === 'x' ? r : (r & 0x3) | 0x8; // tslint:disable-line
    return v.toString(16);
  });
};

export const isFileImageType = (file: File): boolean => {
  return file.type && file.type.substring(0, 6) === 'image/';
};
