import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@apps/api-interfaces';

@Injectable()
export class SignupService {
  constructor(private http: HttpClient) {}

  async create(data): Promise<any> {
    const url = API.USER;
    return await this.http.post<any>(url, data).toPromise();
  }
}
