/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./error-message.component";
var styles_ErrorMessageComponent = [i0.styles];
var RenderType_ErrorMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorMessageComponent, data: { "animation": [{ type: 7, name: "slide", definitions: [{ type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1, transform: "translate{{ axis }}(0{{ util }})" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "{{ opacity }}", transform: "translate{{ axis }}({{ width }}{{ util }})" }, offset: null }, timings: "{{ duration }} {{ delay }}ms cubic-bezier(0.35, 0, 0.25, 1)" }], options: { params: { duration: "0.3s", width: -100, delay: 0, opacity: 0, util: "%", axis: "X" } } }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "{{ opacity }}", transform: "translate{{ axis }}({{ width }}{{ util }})" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translate{{ axis }}(0{{ util }})" }, offset: null }, timings: "{{ duration }} {{ delay }}ms cubic-bezier(0.35, 0, 0.25, 1)" }], options: { params: { duration: "0.3s", width: -100, delay: 0, opacity: 0, util: "%", axis: "X" } } }], options: {} }] } });
export { RenderType_ErrorMessageComponent as RenderType_ErrorMessageComponent };
function View_ErrorMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "warning"]], [[24, "@slide", 0], [2, "empty", null]], null, null, null, null)), i1.ɵpod(1, { width: 0, axis: 1, delay: 2 }), i1.ɵpod(2, { value: 0, params: 1 }), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, !!_co.hide, _ck(_v, 1, 0, 10, "X", 50)); var currVal_1 = !_co.message; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.message; _ck(_v, 3, 0, currVal_2); }); }
export function View_ErrorMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorMessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hide; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sc-error-message", [], [[2, "empty", null]], null, null, View_ErrorMessageComponent_0, RenderType_ErrorMessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.ErrorMessageComponent, [i1.ChangeDetectorRef], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).empty; _ck(_v, 0, 0, currVal_0); }); }
var ErrorMessageComponentNgFactory = i1.ɵccf("sc-error-message, sc-error, [sc-error]", i3.ErrorMessageComponent, View_ErrorMessageComponent_Host_0, { text: "text" }, {}, []);
export { ErrorMessageComponentNgFactory as ErrorMessageComponentNgFactory };
