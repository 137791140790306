import {
  Component,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';
import { slideAnimations } from '../../../animations/slide.animation';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sc-error-message, sc-error, [sc-error]',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  animations: [slideAnimations]
})
export class ErrorMessageComponent {
  message: string;
  hide = true;

  @Input() set text(value) {
    if (value !== this.message) {
      this.message = value;
      this.hide = !value;
      this.cdr.detectChanges();
    }
  }

  @HostBinding('class.empty') get empty(): boolean {
    return !this.message || this.hide;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  reset(): void {
    this.message = undefined;
    this.hide = true;
    this.cdr.detectChanges();
  }
}
