import { InjectionToken } from '@angular/core';
const ɵ0 = () => `Pole jest wymagane`, ɵ1 = () => `Wymagana akceptacja`, ɵ2 = () => `Błędny adres e-mail`, ɵ3 = () => `Błędny adres e-mail`, ɵ4 = () => `Błędny numer telefonu`, ɵ5 = () => `Błędny kod pocztowy`, ɵ6 = ({ requiredLength, actualLength }) => `Minialna ilość znaków to ${requiredLength} a podano ${actualLength}`;
export const defaultErrors = {
    required: ɵ0,
    requiredTrue: ɵ1,
    email: ɵ2,
    emailFormat: ɵ3,
    phoneNumber: ɵ4,
    zipCode: ɵ5,
    minlength: ɵ6
};
export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
