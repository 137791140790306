import {
    trigger,
    state,
    animate,
    transition,
    style,
    AnimationMetadata,
} from '@angular/animations';

export const scaleAnimation: AnimationMetadata = trigger('scale', [
    state(
        '*',
        style({
            opacity: 1,
        })
    ),
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'scale(0)',
        }),
        animate(
            '200ms ease-in-out',
            style({
                opacity: 1,
                transform: 'scale(1)',
            })
        ),
    ]),
    transition(':leave', [
        style({
            opacity: 1,
            transform: 'scale(1)',
        }),
        animate(
            '150ms ease-in-out',
            style({
                opacity: 0,
                transform: 'scale(0)',
            })
        ),
    ]),
]);
