import { InjectionToken } from '@angular/core';

export interface UploadModuleConfig {
  apiUrl: string;
}

const defaultUploadConfig: UploadModuleConfig = {
  apiUrl: '/api'
};

export const UPLOAD_CONFIG = new InjectionToken('UPLOAD_CONFIG', {
  factory: (): UploadModuleConfig => defaultUploadConfig
});
