export const API_PATH = `/api`;
export const WP_API_PATH = `/wp-json/wp/v2`;

export const API = {
  POSTS: `${API_PATH}/posts`,
  WP_POSTS: `${WP_API_PATH}/posts`,
  CATEGORIES: `${API_PATH}/categories`,
  BANNER: `${API_PATH}/banners`,
  FILTERS: `${API_PATH}/filters`,
  PRODUCTS: `${API_PATH}/products`,
  PROVIDERS: `${API_PATH}/providers`,
  MEDIA: `${API_PATH}/media`,
  AUTH: `${API_PATH}/auth`,
  USER: `${API_PATH}/users`,
  USER_UPDATE: `${API_PATH}/users/update`,
  USER_SETTINGS: `${API_PATH}/users/settings`,
  UPLOAD: `${API_PATH}/upload`,
  SUBMISSIONS: `${API_PATH}/submissions`,
  FAST_CONTACT: `${API_PATH}/fastcontact`,
  CALC: `${API_PATH}/calc/offers`,
  CALC_SINGLE: `${API_PATH}/calc/offer`,
  CALC_COMPETITORS: `${API_PATH}/calc/competitor`,
  NEWSLETTER: `${API_PATH}/newsletter`,
  LOGS: `${API_PATH}/log`,
  SOLARS: {
    ATTRIBUTES: `${API_PATH}/solars/extras/filters`,
    COMPONENTS: `${API_PATH}/calc/solars/filters/components`
  }
};

export const API_CMS = {
  PRICES: `${API_PATH}/prices`,
  SETTINGS: `${API_PATH}/settings`,
  PROVISIONS: `${API_PATH}/provisions`,
  PROVISIONS_STATS: `${API_PATH}/provisions/stats`,
  PROVISIONS_SUMMARY: `${API_PATH}/provisions/summary`,
  CONVERT_IMAGES: `/server/convertimages`,
  SITEMAP_GENERATE: '/server/sitemap/generate',
  SEND_EMAIL: {
    EMAIL_CONTACT: `${API_PATH}/submissions/sendemail/emailoffer`
  },
  SOLARS: {
    COMPONENTS: `${API_PATH}/solars/components`,
    SETS: `${API_PATH}/solars/sets`,
    EXTRAS: `${API_PATH}/solars/extras`,
    EXTRASGROUPS: `${API_PATH}/solars/extras-groups`,
    PRICES: `${API_PATH}/solars/prices`
  },
  UPDATE_PASSWD: `${API_PATH}/users/password`
};
