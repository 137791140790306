import { MongoDbItem } from './mongo.model';
import { FilterItem } from './filter.model';

export class SolarProductItem {
  id: string;
  title: string;
  unitKw?: boolean;
  company?: boolean;
  promotion?: string;
  promoText?: string;
  amount: {
    salesPrice: number;
    salesPriceText: string;
    salesPriceBtc: number;
    salesPriceBtcText: string;
    regularPrice: number;
    regularPriceText: string;
    regularPriceBtc: number;
    regularPriceBtcText: string;
    currentEnergyPrice: number;
    fundingAmount: number;
    fundingTaxAmount: number;
    paybackTime: number;
    paybackTimeText: string;
    freeEnergyDay: string;
    savingCO2: number;
    savingCO2Text: string;
    vatAmountValue: number;
    vatAmountValueText: string;
    btc: any;
  };
  region: {
    zip: string;
    address: string;
    city: string;
    province: string;
    state: string;
    provider: string;
  };
  product: string;
  power: number;
  benefits: {
    title: string;
    name: string;
  }[];
  components: {
    title: string;
    type: string;
    provider: {
      name: string;
      title: string;
      image: string;
      content: string;
    };
    certificates: {
      title: string;
      name: string;
    }[];
    documents: {
      title: string;
      url: string;
    }[];
    attributes: {
      title: string;
      name: string;
      value: string;
      unit?: { id: string; label: string };
    }[];
  }[];
  used: number;
  providers: {
    name: string;
    title: string;
    image: string;
  }[];
  attributes: FilterItem[];
  kitContents: string[];
  extras: {
    title: string;
    values: string[];
  }[];
  summary: {
    priceTooltip?: string;
  };
  includes?: {
    panel?: {
      count: number;
      power: number;
      provider: {
        name: string;
        title: string;
        image: string;
      };
    },
    inverter?: {
      count: number;
      power: number;
      provider: {
        name: string;
        title: string;
        image: string;
      };
    }
  }
}

export class SolarItem {
  title?: string;
  setId?: string;
  description?: string;
  priority?: number;
  active?: boolean;
  promotion?: string;
  promoText?: string;
  items: {
    fromUsed: number;
    regularPrice: number;
    salesPrice: number;
    promotion?: string;
    promoText?: string;
  }[];
}

export interface DbSolarItem extends SolarItem, MongoDbItem {}

/**
 * Components
 */

export const SOLAR_COMPONENT_TYPES = [
  { id: 'panel', label: 'Panele fotowoltaiczne', label1: 'Panele fotow.' },
  { id: 'inwerter', label: 'Inwerter', label1: 'Inwerter' },
  {
    id: 'konst_montaz',
    label: 'Konstrukcja montażowa',
    label2: 'Konstr. montaż.'
  },
  { id: 'klemy', label: 'Klemy łączeniowe', label1: 'Łączenia' },
  { id: 'akcesoria', label: 'Akcesoria montażowe', label1: 'Akcesoria' },
  { id: 'optymalizator', label: 'Optymalizator mocy', label1: 'Optymalizator' },
  {
    id: 'skrz_przyl',
    label: 'Skrzynka przyłączeniowa z zabezpieczeniami DC',
    label1: 'Skrz. przyłączeniowa'
  },
  { id: 'inny', label: 'Inny', label1: 'Oraz' }
];

export class SolarComponentItem {
  title?: string;
  provider?: string;
  type?: string;
  active?: boolean;
  power?: number;
  certificatesIds?: string[];
  attributes: {
    id: string;
    value: string;
  }[];
  documents?: {
    title: string;
    url: string;
  }[];
}

export interface DbSolarComponentItem extends SolarComponentItem, MongoDbItem {}

export class SolarsComponentsFilters {
  power?: number;
  name: string;
  title: string;
  image: string;
  order?: number;
  fullTitle?: string;
  type: 'panel' | 'inwerter';
}

/**
 * Sets
 */

export class SolarSetItem {
  title?: string;
  description?: string;
  componentsIds: string[];
  benefitsIds: string[];
  additionalPriceListId?: string;
  kitContents?: {
    text: string;
  }[];
  documents?: {
    title: string;
    url: string;
  }[];
}

export interface DbSolarSetItem extends SolarSetItem, MongoDbItem {}

/**
 * Extras
 */

export const SOLAR_EXTRAS_TYPES = [
  { id: 'components', label: 'Komponenty' },
  { id: 'certificates', label: 'Certyfikaty' },
  { id: 'sets', label: 'Zestawy' },
  { id: 'benefits', label: 'Zalety zestawów' }
];

export const SOLAR_EXTRAS_TYPES_UNIT = [
  { id: 'kwp', label: 'kWp' },
  { id: 'mwp', label: 'mWh' },
  { id: 'w', label: 'W' },
  { id: 'zl', label: 'PLN' },
  { id: 'lata', label: 'Lata' },
  { id: 'prcent', label: '%' },
  { id: 'm2', label: 'm2' },
  { id: 'kg', label: 'kg' },
  { id: 'kw', label: 'KW' },
  { id: 'mm', label: 'mm' },
  { id: 'mm2', label: 'mm²' },
  { id: 'szt', label: 'szt.' },
  { id: 'oc', label: '°C' },
  { id: 'pa', label: 'Pa' },
  { id: 'dba', label: 'dBA' },
  { id: 'custom', label: 'Dowolny tekst' },
  { id: null, label: 'Nie dotyczy (brak pola na jednostkę)' }
];

export class SolarExtraItem {
  name?: string;
  title?: string;
  shortTitle?: string;
  type?: string;
  unit?: string;
  order?: number;
  inFilters?: boolean;
  icon?: string;
  description?: string;
}

export interface DbSolarExtraItem extends SolarExtraItem, MongoDbItem {}

/**
 * Extras groups
 */

export class SolarExtrasGroupItem {
  title?: string;
  attributes: {
    id: string;
    values: string[];
  }[];
}

export interface DbSolarExtrasGroupItem
  extends SolarExtrasGroupItem,
    MongoDbItem {}

/**
 * Defaults
 */

export const ROOF_DEFAULT = 'other';
export const PRICE_TO_KWH = (price: number, energyPrice = 0.83): number => {
  return price / ((energyPrice - (energyPrice * .30)) * 1000);
};
export const KWH_TO_PRICE = (volume: number, energyPrice = 0.83): number => {
  return (energyPrice * 1000) * volume;
};

/**
 * Solar roofs types
 */

export const SOLARTS_ROOFS_TYPES = [
  { id: 'outside', label: 'Poza dachem', vat: 23 },
  { id: 'open_gable', label: 'Szczyt otwarty', vat: 8 },
  { id: 'salttbox', label: 'Spadzisty dach z jednej strony', vat: 8 },
  { id: 'piramid', label: 'Piramida', vat: 8 },
  { id: 'flat', label: 'Dach płaski', vat: 8 },
  { id: 'm_shaped', label: 'W kształcie litery M', vat: 8 },
  { id: 'dormer', label: 'Z oknami na poddaszu', vat: 8 },
  { id: 'hip_valley', label: 'Dach czterospadowy', vat: 8 },
  { id: 'gambrel', label: 'Dach mansardowy, łamany', vat: 8 },

  { id: 'batterfly', label: 'Dach wklęsły', vat: 8, hidden: true },
  { id: 'lean_to', label: 'Altanka', vat: 8, hidden: true },
  { id: 'mansard', label: 'Dach czterospadowy', vat: 8, hidden: true },
  { id: 'dutch_gable', label: 'Holenderski szczyt', vat: 8, hidden: true },
  { id: 'box_gable', label: 'Szczyt pudełka', vat: 8, hidden: true },
  { id: 'clerestory', label: 'Ściana z oknami ponad nawą boczną', vat: 8, hidden: true },
  { id: 'curved', label: 'Zakrzywiony', vat: 8, hidden: true },
  { id: 'shed', label: 'Dach jednospadowy ', vat: 8, hidden: true },

  { id: 'other', label: 'Inny', vat: 8 }
];
