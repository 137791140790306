import { Injectable } from '@angular/core';
const { detect } = require('detect-browser');

@Injectable({
  providedIn: 'root'
})
export class DetectDeviceService {

  get browser() {
    return detect();
  }

  get isSimulator(): boolean {
    return false; // window.location.hostname === 'localhost';
  }

  get userAgent() {
    return window.navigator.userAgent;
  }

  get isiOS(): boolean {
    return !!this.userAgent.match(/iPad/i) || !!this.userAgent.match(/iPhone/i);
  }

  get isWebkit(): boolean {
    return !!this.userAgent.match(/WebKit/i);
  }

  get isChrome(): boolean  {
    return this.userAgent.indexOf("Chrome") > -1; 
  }

  get isSafari(): boolean  {
    return !this.isChrome && this.userAgent.indexOf("Safari") > -1; 
  }

  setBodyData(): void {
    try {
      const inline = Object.values(this.browser).join('|');
      document.body.setAttribute('data-browser', inline);
    } catch (e) {}
  }

}
